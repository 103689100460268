import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import ArchiveOutlinedIcon from '@material-ui/icons/ArchiveOutlined';
import UnarchiveOutlinedIcon from '@material-ui/icons/UnarchiveOutlined';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import ActivityViewer from '../../../components/ActivityViewer';
import SuccessMessage from '../../../components/SuccessMessage';
import Loader from '../../../components/Loader';
import api from '../../../config/api';
import { updateRecentlyViewed } from '../../../utils/storageHelper';
import Viewers from './Viewers';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({}));

export default function TransactionDetail(props) {
	const { id } = useParams();
	const classes = useStyles();
	const theme = useTheme();

	const [transaction, setTransaction] = useState(null);
	const [activities, setActivities] = useState([]);
	const [transactionLoading, setTransactionLoading] = useState(true);
	const [activitiesLoading, setActivitiesLoading] = useState(true);
	const [triggerUpdate, setTriggerUpdate] = useState(false);
	const [triggerUpdateActivities, setTriggerUpdateActivities] = useState(false);

	const prepareTransactionData = (data) => {
		console.log('prepareTransactionData');

		let hasClientRequest = !isEmpty(data.clientRequestId);
		let clientResponded =
			hasClientRequest && data.clientRequestId?.status === 'Responded';

		let transactionData = {
			...data,
			metaData: {
				hasClientRequest: hasClientRequest,
				clientResponded: clientResponded,
			},
		};
		setTransaction(transactionData);
	};

	const getTransactionData = () => {
		if (!transactionLoading) {
			setTransactionLoading(true);
		}
		api
			.get('transactions/details/' + id)
			.then((response) => {
				if (response.data.ok) {
					prepareTransactionData(response.data.data);
					setTriggerUpdate(false);
					updateRecentlyViewed({
						key: response.data.data.createdBy,
						value: {
							_id: response.data.data._id,
							name: response.data.data.transactionNo,
							type: 'transaction',
						},
					});
				}
				setTransactionLoading(false);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const getActivityData = () => {
		if (!activitiesLoading) {
			setActivitiesLoading(true);
		}
		api
			.get('activities/' + id)
			.then((response) => {
				if (response.data.ok) {
					setTriggerUpdateActivities(false);
					setActivities(response.data.data);
				}
				setActivitiesLoading(false);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	useEffect(() => {
		getTransactionData();
		getActivityData();
	}, [id]);

	useEffect(() => {
		if (transaction) {
			document.title = `TXN-${transaction.transactionNo} - Uuoni`;
		}
		return () => {
			document.title = 'Uuoni'; // Reset title when leaving the page
		};
	}, [transaction]);

	useEffect(() => {
		if (triggerUpdate) {
			getTransactionData();
			getActivityData();
		}
	}, [triggerUpdate]);

	useEffect(() => {
		if (triggerUpdateActivities) {
			getActivityData();
		}
	}, [triggerUpdateActivities]);

	const archiveTransaction = (e) => {
		e.preventDefault();
		if (!transactionLoading) {
			// setTransactionLoading(true);
			try {
				api
					.put(`transactions/${transaction._id}/archive`, {
						value: transaction.archived ? false : true,
					})
					.then((res) => {
						if (res.data.ok) {
							toast.success(
								transaction.archived
									? 'Transaction Unarchived!'
									: 'Transaction Archived!'
							);
							// setTransactionLoading(false);
							setTriggerUpdate(true);
						} else {
							toast.error(res.data.error);
							// setTransactionLoading(false);
						}
					});
			} catch (error) {
				console.log(error);
				toast.error(error);
			}
		}
	};

	const getHeader = () => {
		return (
			<>
				{`${transaction.archived ? '(Archived) - ' : ''}${
					transaction.insuranceType
				} ${
					transaction.insuranceSubType && ` (${transaction.insuranceSubType})`
				} ${transaction.transactionType} ${
					transaction.isDirectPurchase ? ` (via Insurer Portal)` : ''
				} - ${transaction.client.contactName}`}
			</>
		);
	};

	if (transactionLoading) return <Loader size='lg' />;
	if (!transaction) return <SuccessMessage type={'invalid-request'} />;

	return (
		<>
			<Breadcrumbs separator='›' aria-label='breadcrumb'>
				<Link component={RouterLink} color='inherit' to={'/transactions'}>
					Transactions
				</Link>
				<Typography color='inherit'>Transaction Overview</Typography>
				<Typography color='primary'>
					TXN-{transaction.transactionNo}
					{transaction.archived && ' - Archived'}
				</Typography>
			</Breadcrumbs>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
				}}
			>
				<Button
					style={{
						marginTop: 24,
						marginBottom: 24,
					}}
					variant='outlined'
					component={RouterLink}
					color='primary'
					startIcon={<NavigateBeforeIcon />}
					to={'/transactions'}
				>
					Back
				</Button>
				<Button
					style={{
						marginTop: 24,
						marginBottom: 24,
					}}
					variant='outlined'
					color='primary'
					startIcon={
						transaction.archived ? (
							<UnarchiveOutlinedIcon />
						) : (
							<ArchiveOutlinedIcon />
						)
					}
					onClick={(e) => archiveTransaction(e)}
				>
					{transaction.archived ? 'Unarchive' : 'Archive'}
				</Button>
			</div>
			<Typography variant='h2' style={{ marginBottom: 24 }}>
				{getHeader()}
			</Typography>
			<Grid
				style={{ height: '80%' }}
				container
				spacing={3}
				direction='row'
				justifyContent='center'
				alignItems='stretch'
			>
				<Grid item xs={12} md={8} style={{ height: '100%' }}>
					<Viewers
						transaction={transaction}
						setTransaction={setTransaction}
						setTriggerUpdate={setTriggerUpdate}
						setTriggerUpdateActivities={setTriggerUpdateActivities}
					/>
				</Grid>
				<Grid item xs={12} md={4} style={{ height: '100%' }}>
					<ActivityViewer activities={activities} loading={activitiesLoading} />
				</Grid>
			</Grid>
		</>
	);
}
