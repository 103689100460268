import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import './styles.css';

import {
	CloseTransaction,
	DeleteTransactions,
	ResendClientRequest,
	ResendRequest,
	SendClientRequestForQuoteSelection,
	SendRequestForClaims,
	SendRequestForProcessingQuote,
	SendRequestForQuotations,
	UpdateRecommendations,
	NotifyClaim,
	SendRenewalNotice,
} from './transactions/';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3} style={{ padding: 0 }}>
					{children}
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
		'aria-controls': `full-width-tabpanel-${index}`,
	};
}

const useStyles = makeStyles((theme) => ({
	paper: {
		minWidth: 560,
		padding: 32,
		backgroundColor: theme.palette.background.default,
		boxShadow:
			'0px 3px 11px 0px #818181, 0 3px 3px -2px #b2b2b21a, 0 1px 8px 0 #9a9a9a1a',
		borderRadius: 8,
	},
}));

const getBody = (props) => {
	switch (props.body) {
		case 'closeTransaction':
			return CloseTransaction(props);
		case 'deleteTransactions':
			return DeleteTransactions(props);

		case 'resendClientRequest':
			return ResendClientRequest(props);
		case 'resendRequest':
			return ResendRequest(props);

		case 'sendClientRequestForQuoteSelection':
			return SendClientRequestForQuoteSelection(props);
		case 'sendRequestForClaims':
			return SendRequestForClaims(props);
		case 'sendRequestForQuotations':
			return SendRequestForQuotations(props);

		case 'sendRequestForProcessingQuote':
			return SendRequestForProcessingQuote(props);
		case 'updateRecommendations':
			return UpdateRecommendations(props);
		case 'notifyClaim':
			return NotifyClaim(props);
		case 'sendRenewalNotice':
			return SendRenewalNotice(props);
		default:
			return 'Unknown modal';
	}
};

const TransactionModal = (props) => {
	const classes = useStyles();
	const theme = useTheme();

	return (
		<Dialog
			open={props.open}
			onClose={props.handleClose}
			maxWidth={props.size}
			aria-labelledby='alert-dialog-title'
			aria-describedby='alert-dialog-description'
		>
			<div className={classes.paper}>{getBody({ ...props, theme: theme })}</div>
		</Dialog>
	);
};

export default TransactionModal;
