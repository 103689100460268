import AppBar from '@material-ui/core/AppBar';
import Backdrop from '../../../components/Backdrop';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import React, { useEffect, useState } from 'react';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Tooltip from '../../../components/Tooltip';
import FileUploader from '../../../components/FileUploader';
import SuccessMessage from '../../../components/SuccessMessage';
import api from '../../../config/apiPublic';
import ClientLogin from '../ClientLogin';
import useStyles from '../styles';
import Loader from '../../../components/Loader';
import { SessionTimeout } from '../../../components/Session';

const printReqString = () => {
	let base = 'Acceptable formats:';
	let docBase = 'Document';
	let docSize = '- size must not exceed 50mb';
	let docReq = '- .xls, .xlsx, .xlsm, .csv, .doc, .docx, .pdf, .txt';
	let vidBase = 'Video';
	let vidSize = '- size must not exceed 50mb';
	let vidReq = '- .avi, .mp4, .mpeg, .webm';
	let imageBase = 'Image';
	let imageSize = '- size must not exceed 50mb';
	let imageReq = '- .jpg, .jpeg, .png, .bmp';

	return (
		<div style={{ whiteSpace: 'pre-line' }}>
			<Typography variant='body2' color='textPrimary'>
				{base}
			</Typography>
			<Typography style={{ marginTop: 16 }} variant='body2' color='textPrimary'>
				{docBase}
			</Typography>
			<Typography variant='body1' color='textPrimary'>
				{`${docSize}\n${docReq}`}
			</Typography>
			<Typography style={{ marginTop: 16 }} variant='body2' color='textPrimary'>
				{vidBase}
			</Typography>
			<Typography variant='body1' color='textPrimary'>
				{`${vidSize}\n${vidReq}`}
			</Typography>
			<Typography style={{ marginTop: 16 }} variant='body2' color='textPrimary'>
				{imageBase}
			</Typography>
			<Typography variant='body1' color='textPrimary'>
				{`${imageSize}\n${imageReq}`}
			</Typography>
			{/* {`${base}\n\n${docBase}\n${docSize}\n${docReq}\n
			${vidBase}\n${vidSize}\n${vidReq}\n
			${imageBase}\n${imageSize}\n${imageReq}\n`} */}
		</div>
	);
};

const fileType = (fileName) => {
	return (
		fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) ||
		fileName
	);
};

function FileUploadCard(props) {
	const { index, file, fileList, setFileList } = props;
	const classes = useStyles();

	const handleFileChange = (e, files) => {
		e.preventDefault();
		let updatedFileList = [...fileList];
		updatedFileList[index].fileData = files;
		if (files.length > 0) {
			updatedFileList[index].submitted = true;
		} else {
			updatedFileList[index].submitted = false;
		}
		setFileList(updatedFileList);
	};

	return (
		<Card
			style={{
				border: fileList[index].submitted
					? '1px solid #66CB94'
					: '1px solid #B7C6F3',
				borderRadius: 8,
				boxShadow: 'unset',
				height: '100%',
			}}
		>
			<CardContent
				className={classes.cardContent}
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'flex-start',
					padding: 16,
				}}
			>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						width: '100%',
						marginBottom: 16,
					}}
				>
					<Typography color='textPrimary' variant='h3' align='left'>
						{file.documentName}
					</Typography>
				</div>
				<div
					style={{
						width: '100%',
						marginBottom: file.prevDisplayName ? 24 : 'auto',
					}}
				>
					<Typography
						style={{
							marginBottom: 8,
						}}
						variant='body2'
						color='textPrimary'
					>
						Remarks
					</Typography>
					<Typography
						style={{
							marginBottom: 8,
						}}
						variant='body1'
						color='textPrimary'
					>
						{file.remarks ? file.remarks : 'N/A'}
					</Typography>
				</div>
				{file.prevDisplayName ? (
					<div
						style={{
							width: '100%',
							marginBottom: 'auto',
						}}
					>
						<Typography
							style={{
								marginBottom: 8,
							}}
							variant='body2'
							color='textPrimary'
						>
							Previously uploaded Document
						</Typography>
						<Card>
							<CardContent
								style={{
									display: 'flex',
									alignItems: 'center',
									width: '100%',
									height: '100%',
									paddingBottom: 16,
								}}
							>
								<div style={{ width: 24 }}>
									<FileIcon
										labelUppercase
										extension={fileType(file.prevDisplayName)}
										{...defaultStyles[fileType(file.prevDisplayName)]}
									/>
								</div>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										marginLeft: 20,
									}}
								>
									<Typography
										variant='body1'
										color='textPrimary'
										component='span'
									>
										{file.prevDisplayName}
									</Typography>
								</div>
							</CardContent>
						</Card>
					</div>
				) : (
					<div
						style={{
							width: '100%',
							marginBottom: 'auto',
						}}
					>
						<FileUploader
							fileLimit={1}
							files={file.fileData}
							handleFileChange={handleFileChange}
							documentType={file.fileType}
						/>
					</div>
				)}
			</CardContent>
		</Card>
	);
}

function ProposalFormUploadCard(props) {
	const { proposalFormData, proposalForm, setProposalForm, type } = props;
	const classes = useStyles();

	const handleFileChange = (e, files) => {
		e.preventDefault();
		setProposalForm((prev) => ({
			...prev,
			fileData: files,
			submitted: files.length > 0 ? true : false,
		}));
	};

	return (
		<Card
			style={{
				border: proposalForm.submitted
					? '1px solid #66CB94'
					: '1px solid #B7C6F3',
				borderRadius: 8,
				boxShadow: 'unset',
				height: '100%',
			}}
		>
			<CardContent
				className={classes.cardContent}
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'flex-start',
					padding: 16,
				}}
			>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						width: '100%',
						marginBottom: 16,
					}}
				>
					<Typography color='textPrimary' variant='h3' align='left'>
						{type === 'Claims'
							? 'Completed Claim Form'
							: 'Completed Proposal Form'}
					</Typography>
				</div>
				<div
					style={{
						width: '100%',
						marginBottom: proposalForm.prevDisplayName ? 24 : 'auto',
					}}
				>
					<Typography
						style={{
							marginBottom: 8,
						}}
						variant='body2'
						color='textPrimary'
					>
						Remarks
					</Typography>
					<Typography
						style={{
							marginBottom: 8,
						}}
						variant='body1'
						color='textPrimary'
					>
						Please upload the completed proposal/claim form that was either
						provided or downloaded
					</Typography>
				</div>
				{proposalForm.prevDisplayName ? (
					<div
						style={{
							width: '100%',
							marginBottom: 'auto',
						}}
					>
						<Typography
							style={{
								marginBottom: 8,
							}}
							variant='body2'
							color='textPrimary'
						>
							Previously uploaded Document
						</Typography>
						<Card>
							<CardContent
								style={{
									display: 'flex',
									alignItems: 'center',
									width: '100%',
									height: '100%',
									paddingBottom: 16,
								}}
							>
								<div style={{ width: 24 }}>
									<FileIcon
										labelUppercase
										extension={fileType(proposalForm.prevDisplayName)}
										{...defaultStyles[fileType(proposalForm.prevDisplayName)]}
									/>
								</div>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										marginLeft: 20,
									}}
								>
									<Typography
										variant='body1'
										color='textPrimary'
										component='span'
									>
										{proposalForm.prevDisplayName}
									</Typography>
								</div>
							</CardContent>
						</Card>
					</div>
				) : (
					<div
						style={{
							width: '100%',
							marginBottom: 'auto',
						}}
					>
						<FileUploader
							fileLimit={1}
							files={proposalForm.fileData}
							handleFileChange={handleFileChange}
						/>
					</div>
				)}
			</CardContent>
		</Card>
	);
}

export default function FileRequest(props) {
	const { id } = useParams();
	const classes = useStyles();
	const theme = useTheme();
	const [fileRequest, setFileRequest] = useState(null);
	const [fileList, setFileList] = useState([]);
	const [proposalForm, setProposalForm] = useState({});
	const [isFormInvalid, setIsFormInvalid] = useState(false);
	const [success, setSuccess] = useState(false);
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(true);
	const [hasAuth, setHasAuth] = useState(false);

	const getData = () => {
		if (!loading) {
			setLoading(true);
		}
		api
			.get(`external/client-requests/fileRequest/${id}`)
			.then((response) => {
				if (response.data.ok) {
					setFileRequest(response.data.data);
					setFileList(response.data.data.fileList);
					setProposalForm(response.data.data.proposalForm);
				} else {
					setFileRequest(null);
				}
				setLoading(false);
			})
			.catch(function (error) {
				setFileRequest(null);
				setLoading(false);
				console.log(error);
			});
	};

	// useEffect(() => {
	// 	if (hasAuth) getData();
	// }, [hasAuth]);
	useEffect(() => {
		getData();
	}, []);

	const handleValidation = () => {
		if (fileList.find((file) => !file.submitted && !file.prevDisplayName)) {
			return true;
		} else if (
			fileRequest?.hasProposalForm &&
			!proposalForm.submitted &&
			!proposalForm.prevDisplayName
		) {
			return true;
		} else {
			return false;
		}
	};

	useEffect(() => {
		setIsFormInvalid(handleValidation());
	}, [fileList, proposalForm]);

	const getHeader = () => {
		return (
			<>
				Supporting Document Upload - {fileRequest.insuranceType}
				{fileRequest.insuranceSubType && (
					<> ({fileRequest.insuranceSubType})</>
				)}{' '}
				{fileRequest.transactionType} - {fileRequest.client.contactName}
			</>
		);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (open) return;
		setOpen(true);
		const data = new FormData();
		if (fileRequest.hasProposalForm && proposalForm.fileData) {
			data.append(
				fileRequest.transactionType === 'Claims'
					? 'Claim Form'
					: 'Proposal Form',
				proposalForm.fileData[0],
				proposalForm.fileData[0].name
			);
		}
		fileList.forEach((file) => {
			if (file.fileData) {
				data.append(file.documentName, file.fileData[0], file.fileData[0].name);
			}
		});

		try {
			api
				.put(`external/client-requests/fileRequest/${id}`, data)
				.then((res) => {
					if (res.data.ok) {
						setSuccess(true);
						setOpen(false);
					} else {
						setSuccess(false);
						toast.error(res.data.error);
						setOpen(false);
					}
				});
		} catch (error) {
			console.log(error);
			setSuccess(false);
			toast.error(error);
			setOpen(false);
		}
	};

	// if (!hasAuth)
	// 	return (
	// 		<ClientLogin
	// 			reqType='fileRequest'
	// 			reqId={id}
	// 			hasAuth={hasAuth}
	// 			setHasAuth={setHasAuth}
	// 		/>
	// 	);

	// if (hasAuth && loading && !fileRequest) return <Loader size='lg' />;
	// if (hasAuth && !loading && !fileRequest)
	if (loading && !fileRequest) return <Loader size='lg' />;
	if (!loading && !fileRequest)
		return (
			<>
				<AppBar position='fixed' className={classes.appBar}>
					<Toolbar className={classes.toolBar} disableGutters>
						<div className='brandContainer'>
							<Button
								target='_blank'
								href='https://www.uuoni.com'
								style={{ padding: 0, backgroundColor: 'transparent' }}
								disableRipple
								disableFocusRipple
								disableElevation
							>
								<img
									className='brand-logo'
									src={`${process.env.REACT_APP_CDN_URL}/logo-brand-white.png`}
									alt=''
								/>
							</Button>
						</div>
					</Toolbar>
				</AppBar>
				<SuccessMessage type={'request-not-found'} />
			</>
		);
	return (
		<>
			<AppBar position='fixed' className={classes.appBar}>
				<Toolbar className={classes.toolBar} disableGutters>
					<div className='brandContainer'>
						<Button
							target='_blank'
							href='https://www.uuoni.com'
							style={{ padding: 0, backgroundColor: 'transparent' }}
							disableRipple
							disableFocusRipple
							disableElevation
						>
							<img
								className='brand-logo'
								src={`${process.env.REACT_APP_CDN_URL}/logo-brand-white.png`}
								alt=''
							/>
						</Button>
					</div>
				</Toolbar>
			</AppBar>
			{fileRequest && fileRequest.status !== 'Pending Response' ? (
				<SuccessMessage type={'invalid-request'} />
			) : (
				<>
					{success ? (
						<SuccessMessage type={'file-uploaded'} />
					) : (
						<div className={classes.content}>
							<Backdrop text='Processing Information...' open={open} />
							<div className='header' style={{ marginBottom: 32 }}>
								<Typography variant='h1' color='textPrimary'>
									{getHeader()}
								</Typography>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										marginTop: 16,
									}}
								>
									<Typography
										style={{ textTransform: 'uppercase' }}
										variant='h5'
										color='textPrimary'
									>
										Requested by{' '}
										{`${fileRequest.agentInfo.firstName} ${fileRequest.agentInfo.lastName}`}
									</Typography>
									<div style={{ marginLeft: 16 }}>
										<IconButton
											component={Link}
											target='_blank'
											href={`tel:${fileRequest.agentInfo.mobileNo}`}
											size='small'
										>
											<WhatsAppIcon />
										</IconButton>
										<IconButton
											component={Link}
											href={`mailto:${fileRequest.agentInfo.email}`}
											size='small'
										>
											<MailOutlineIcon />
										</IconButton>
									</div>
								</div>
							</div>
							<div className='body' style={{ marginBottom: 32 }}>
								{fileRequest.hasProposalForm && (
									<>
										<Typography
											style={{ textTransform: 'uppercase' }}
											variant='h3'
											color='textPrimary'
										>
											Proposal Form (To be completed)
										</Typography>
										<Divider
											style={{
												backgroundColor: theme.palette.primary.light,
												height: 2,
												marginTop: 16,
												marginBottom: 16,
											}}
										/>
										<div
											style={{
												width: '100%',
												marginBottom: 24,
											}}
										>
											{fileRequest.proposalForm.method === 'url' ? (
												<>
													<Typography
														style={{
															marginBottom: 16,
														}}
														variant='body2'
													>
														Download Instructions
													</Typography>
													<Typography
														style={{
															marginBottom: 16,
														}}
														variant='body1'
														color='textPrimary'
													>
														{fileRequest.proposalForm.instructions
															? fileRequest.proposalForm.instructions
															: 'N/A'}
													</Typography>
													<Typography
														style={{
															marginBottom: 16,
														}}
														variant='body2'
														color='textPrimary'
													>
														Website Address/URL
													</Typography>
													<Typography
														style={{
															display: 'inline-block',
															wordBreak: 'break-all',
															marginBottom: 16,
														}}
														variant='body1'
														color='textPrimary'
														component='a'
														href={fileRequest.proposalForm.url}
													>
														{fileRequest.proposalForm.url}
													</Typography>
												</>
											) : (
												<Chip
													icon={
														<div>
															<FileIcon
																labelUppercase
																extension={fileType(
																	fileRequest.proposalForm.displayName
																)}
																{...defaultStyles[
																	fileType(fileRequest.proposalForm.displayName)
																]}
															/>
														</div>
													}
													label={`${fileRequest.proposalForm.documentName} (${fileRequest.proposalForm.displayName})`}
													className={classes.chip}
													onClick={() =>
														window.open(fileRequest.proposalForm.url, '_blank')
													}
													variant='outlined'
													color='primary'
												/>
											)}
										</div>
									</>
								)}
								<Typography
									style={{ textTransform: 'uppercase' }}
									variant='h3'
									color='textPrimary'
								>
									List of document(s) needed to submit to Insurance Company
								</Typography>
								<Divider
									style={{
										backgroundColor: theme.palette.primary.light,
										height: 2,
										marginTop: 16,
										marginBottom: 16,
									}}
								/>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										marginBottom: 16,
									}}
								>
									<Typography
										style={{ marginRight: 8 }}
										color='textSecondary'
										variant='body1'
									>
										Acceptable File Formats
									</Typography>
									<Tooltip content={printReqString()} />
								</div>
								<Grid container spacing={3}>
									{fileRequest.hasProposalForm && (
										<Grid
											key={'proposal-form'}
											item
											xs={12}
											sm={6}
											md={4}
											lg={3}
										>
											<ProposalFormUploadCard
												proposalFormData={fileRequest.proposalForm}
												proposalForm={proposalForm}
												setProposalForm={setProposalForm}
												type={fileRequest.transactionType}
											/>
										</Grid>
									)}
									{fileList &&
										fileList.map((file, index) => (
											<Grid key={index} item xs={12} sm={6} md={4} lg={3}>
												<FileUploadCard
													index={index}
													file={file}
													fileList={fileList}
													setFileList={setFileList}
												/>
											</Grid>
										))}
								</Grid>
							</div>
							<div
								className='form-footer'
								style={{ display: 'flex', alignItems: 'center' }}
							>
								<Button
									style={{
										marginLeft: 'auto',
									}}
									variant='contained'
									color='primary'
									disabled={isFormInvalid || loading || open}
									onClick={handleSubmit}
								>
									Submit
								</Button>
							</div>
						</div>
					)}
				</>
			)}
			{/* {hasAuth && <SessionTimeout onTimeout={() => setHasAuth(false)} />} */}
		</>
	);
}
