import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { format } from 'date-fns';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import api from '../../../config/api';

const useStyles = makeStyles((theme) => ({
	paper: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		height: '100%',
		padding: '16px 0px',
		backgroundColor: theme.palette.background.paper,
		boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
		borderRadius: 4,
	},
	tableContainer: {
		height: '100%',
	},
	tableHeader: {
		backgroundColor: theme.palette.background.paper,
		padding: '8px 16px',
	},
	tableCell: {
		padding: '8px 16px',
		borderBottom: 'unset',
	},
}));

export default function PoliciesExpiring() {
	const classes = useStyles();
	const theme = useTheme();

	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);

	const getData = () => {
		if (!loading) {
			setLoading(true);
		}
		api
			.get('widgets/policies-expiring')
			.then((response) => {
				if (response.data.ok) {
					setData(response.data.data);
				}
				setLoading(false);
			})
			.catch(function (error) {
				console.log(error);
				setLoading(false);
			});
	};

	useEffect(() => {
		getData();
	}, []);

	const transactionList = () => {
		return data.map((obj, i) => {
			return (
				<TableRow key={i} style={{ height: 'unset' }}>
					<TableCell className={classes.tableCell}>
						<Link
							component={RouterLink}
							color='primary'
							to={'/transactions/' + obj._id}
						>
							TXN-{obj.transactionNo}
						</Link>
					</TableCell>
					<TableCell className={classes.tableCell}>
						<Typography color='textPrimary' variant='body1' align='left'>
							{obj.client.contactName}
						</Typography>
					</TableCell>
					<TableCell className={classes.tableCell}>
						<Typography color='textPrimary' variant='body1' align='left'>
							{obj.insuranceType}
						</Typography>
					</TableCell>
					<TableCell className={classes.tableCell}>
						<Typography
							color='textPrimary'
							variant='body1'
							align='left'
							style={{ color: '#f00606' }}
						>
							{format(new Date(obj.policyData.endDate), 'dd/MM/yyyy')}
						</Typography>
					</TableCell>
				</TableRow>
			);
		});
	};

	return (
		<div className={classes.paper}>
			<Typography
				style={{ padding: '0px 16px 16px 16px' }}
				variant='h4'
				color='textPrimary'
			>
				Policies Expiring
			</Typography>
			{!data ? (
				<div style={{ display: 'flex', width: '100%', height: '100%' }}>
					{loading ? (
						<CircularProgress style={{ margin: 'auto' }} />
					) : (
						<Typography
							style={{ margin: 'auto' }}
							variant='body1'
							color='textPrimary'
						>
							No Data Available
						</Typography>
					)}
				</div>
			) : (
				<div style={{ width: '100%', height: '85%' }}>
					<TableContainer className={classes.tableContainer}>
						<Table stickyHeader>
							<TableHead>
								<TableRow style={{ height: 'unset' }}>
									<TableCell
										className={classes.tableHeader}
										style={{ width: '25%' }}
									>
										Transaction No.
									</TableCell>
									<TableCell
										className={classes.tableHeader}
										style={{ width: '30%' }}
									>
										Client
									</TableCell>
									<TableCell
										className={classes.tableHeader}
										style={{ width: '25%' }}
									>
										Insurance
									</TableCell>
									<TableCell className={classes.tableHeader}>
										Expiry Date
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>{transactionList()}</TableBody>
						</Table>
					</TableContainer>
				</div>
			)}
		</div>
	);
}
