import React, { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer/dist/index.legacy.esm.js';

const SessionTimeout = ({ onTimeout, duration = 1800 }) => {
	// const [lastActivity, setLastActivity] = useState(Date.now());
	const [isIdle, setIsIdle] = useState(false);
	const [idleTime, setIdleTime] = useState(duration);
	const [remaining, setRemaining] = useState(0);

	const handleIdle = () => {
		// console.log('is idle');
		// setIsIdle(true);
		onTimeout();
	};

	const { getRemainingTime } = useIdleTimer({
		onIdle: handleIdle,
		timeout: idleTime * 1000,
		throttle: 500,
	});

	// useEffect(() => {
	// 	console.log('render timeout');
	// 	console.log(duration);
	// }, []);

	// useEffect(() => {
	// 	const interval = setInterval(() => {
	// 		setRemaining(Math.ceil(getRemainingTime() / 1000));
	// 	}, 500);

	// 	return () => {
	// 		clearInterval(interval);
	// 	};
	// });

	return <></>; // Placeholder component, can be empty
};

export default SessionTimeout;
