import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';

const ShareLink = ({ url }) => {
	const [copied, setCopied] = useState(false);

	const copyToClipboard = () => {
		navigator.clipboard.writeText(url);
		setCopied(true);
		setTimeout(() => setCopied(false), 2000);
	};

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<Typography
				color='textPrimary'
				variant='body1'
				align='left'
				style={{ whiteSpace: 'pre-wrap', textAlign: 'center' }}
			>
				Alternatively, you can share the link below directly using your
				preferred method.
			</Typography>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					marginTop: 8,
				}}
			>
				<Typography
					color='textPrimary'
					variant='body1'
					align='left'
					style={{ whiteSpace: 'pre-wrap', marginRight: 8 }}
				>
					{url}
				</Typography>
				<Button color='primary' variant='outlined' onClick={copyToClipboard}>
					<ShareOutlinedIcon style={{ marginRight: 8 }} />
					{copied ? 'Copied!' : 'Copy Link'}
				</Button>
			</div>
		</div>
	);
};

export default ShareLink;
