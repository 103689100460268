import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DateFnsUtils from '@date-io/date-fns';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import Typography from '@material-ui/core/Typography';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { isEmpty, cloneDeep } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Joi from 'joi';
import { Link as RouterLink } from 'react-router-dom';
import api from '../../../../../../config/api';
import { getPolicyDocument } from '../../../../../../utils/formHelper';
import Tooltip from '../../../../../../components/Tooltip';
import FileInfoCard from '../../../../../../components/FileInfoCard';
import FileUploader from '../../../../../../components/FileUploader';
import { getDiff } from '../../../../../../utils/formHelper';
import { PolicyInfo } from '../../../../../../utils/validators/schemas';
import { useUserProfile } from '../../../../../../components/Session';
import { Edit, Save } from '../../../../../../components/Icons';
import TransactionModal from '../../../../../../components/Modals/transactions';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		height: 120,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
}));

const currencyFormatter = new Intl.NumberFormat(undefined, {
	style: 'currency',
	currency: 'SGD',
});

const initFormData = {
	policyNo: '',
	principalId: '',
	principalName: '',
	premiumAmt: null,
	sumInsured: null,
	startDate: null,
	endDate: null,
	commission: null,
	remarks: '',
	fileData: null,
};

const printReqString = () => {
	let base = 'Acceptable formats:';
	let docBase = 'Document';
	let docSize = '- size must not exceed 50mb';
	let docReq = '- .xls, .xlsx, .xlsm, .csv, .doc, .docx, .pdf, .txt';

	return (
		<div style={{ whiteSpace: 'pre-line' }}>
			<Typography variant='body2' color='textPrimary'>
				{base}
			</Typography>
			<Typography style={{ marginTop: 16 }} variant='body2' color='textPrimary'>
				{docBase}
			</Typography>
			<Typography variant='body1' color='textPrimary'>
				{`${docSize}\n${docReq}`}
			</Typography>
		</div>
	);
};

const stepMapping = {
	'In-Progress:Pending Policy Info': true,
	'Approved In-Principle:Pending Policy Info': true,
	'Completed:Policy In Force': true,
	Default: false,
};

function enablePolicyInfo(transaction) {
	const { status, subStatus } = transaction;
	const key = `${status}:${subStatus}`;
	return stepMapping[key] || stepMapping['Default'];
}

function getPrincipalId(principalList, name) {
	let val = principalList.find((principal) => principal.shortName === name);
	if (val) {
		return val._id;
	} else {
		return '';
	}
}

export default function PolicyDetailsViewer(props) {
	const {
		transaction,
		setTransaction,
		setTriggerUpdate,
		setTriggerUpdateActivities,
	} = props;
	const userProfile = useUserProfile();
	const classes = useStyles();
	const theme = useTheme();

	const isFirstEntry = transaction.subStatus === 'Pending Policy Info';

	const [optionData, setOptionData] = useState([]);
	const [editable, setEditable] = useState(false);
	const [currentData, setCurrentData] = useState(
		!isEmpty(transaction.policyData) ? cloneDeep(transaction.policyData) : null
	);
	const [updatedData, setUpdatedData] = useState(
		!isEmpty(transaction.policyData) ? cloneDeep(transaction.policyData) : null
	);
	const [errorMessages, setErrorMessages] = useState({});
	const [loading, setLoading] = useState(false);

	const [modalOpen, setModalOpen] = useState(false);
	const [modalType, setModalType] = useState(null);

	const hasPolicyData = () => {
		if (isEmpty(transaction.policyData)) {
			if (updatedData) {
				return true;
			}
			return false;
		} else {
			return true;
		}
	};

	const getPrincipalList = () => {
		if (!loading) {
			setLoading(true);
		}
		api
			.get('principals/list/active')
			.then((res) => {
				setOptionData(res.data.data);
				setLoading(false);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	useEffect(() => {
		getPrincipalList();
	}, []);

	const handleModalOpen = (body) => {
		setModalType(body);
		setModalOpen(true);
	};

	const handleModalClose = () => {
		setModalOpen(false);
		setModalType(null);
	};

	const handleFileChange = (e, files) => {
		e.preventDefault();
		setUpdatedData((prev) => ({
			...prev,
			fileData: files,
		}));
	};

	const handleInputChange = (e) => {
		const {
			target: { value, type, name },
		} = e;

		const keys = name.split('.');
		const updateValue =
			type === 'number' && value !== '' ? parseFloat(value) : value;

		const updateNestedData = (data, keyIndex = 0) => {
			if (keyIndex === keys.length - 1) {
				return { ...data, [keys[keyIndex]]: updateValue };
			} else {
				return {
					...data,
					[keys[keyIndex]]: updateNestedData(
						data[keys[keyIndex]],
						keyIndex + 1
					),
				};
			}
		};

		setUpdatedData((prev) => {
			if (keys[0] === 'principalId') {
				let val = optionData.find((principal) => principal._id === value);
				return {
					...prev,
					principalId: val ? val._id : '',
					principalName: val ? val.shortName : '',
				};
			} else {
				return updateNestedData(prev);
			}
		});
	};

	const handleDateChange = (target, date) => {
		let updatedDate = date;

		if (target === 'endDate') {
			updatedDate = new Date(date);
			updatedDate.setHours(23, 59, 59, 999); // Set to end of the day
		}

		setUpdatedData((prev) => ({
			...prev,
			[target]: updatedDate,
		}));
	};

	const handleValidation = () => {
		let errorData = {};
		let dataToValidate = {};
		let schemaObject = {};
		let result = {};
		let isValid = true;

		dataToValidate['policyData'] = {
			...updatedData,
			principalId:
				updatedData.principalName && !updatedData.principalId
					? getPrincipalId(optionData, updatedData.principalName)
					: updatedData.principalId,
		};
		schemaObject['policyData'] = PolicyInfo;

		if (isFirstEntry && isEmpty(updatedData.fileData)) {
			errorData['policyDocument'] = 'Policy Document is required';
			isValid = false;
		}

		let schema = Joi.object(schemaObject);
		result = schema.validate(dataToValidate, {
			abortEarly: false,
		});

		if (result.error === undefined && isValid) {
			setErrorMessages({});
			return true;
		} else {
			if (result.error) {
				console.log(result.error.details);
				for (let item of result.error.details) {
					const key = item.path.join('.');
					const message = item.message;
					errorData[key] = message;
				}
			}
			console.log(errorData);
			setErrorMessages(errorData);
			return false;
		}
	};

	const handleSave = () => {
		if (!loading) {
			console.log('handleSave');
			setLoading(true);

			let valid = handleValidation();
			if (valid) {
				let changedData = !isFirstEntry
					? getDiff(updatedData, currentData)
					: null;

				console.log(changedData);

				if (!changedData && !isFirstEntry) {
					setErrorMessages({});
					setEditable(false);
					setLoading(false);
				} else {
					try {
						const data = new FormData();
						let json = cloneDeep(updatedData);

						if (json.principalName && !json.principalId) {
							json.principalId = getPrincipalId(
								optionData,
								updatedData.principalName
							);
						}

						if (!isEmpty(json.fileData)) {
							data.append(
								'Policy Document',
								json.fileData[0],
								json.fileData[0].name
							);
							delete json.fileData;
						}

						let formData = {
							updatedFields: {
								policyData: json,
							},
							auditLog: changedData,
						};

						console.log(formData);
						data.append('json', JSON.stringify(formData));

						if (isFirstEntry) {
							if (transaction.isDirectPurchase) {
								api
									.put(
										`transactions/${transaction._id}/closeDirectPurchaseTransaction`,
										data
									)
									.then((res) => {
										if (res.data.ok) {
											setErrorMessages({});
											setEditable(false);
											setTriggerUpdate(true);
											toast.success('Policy Info saved!');
										} else {
											toast.error(res.data.error);
										}
									});
							} else {
								api
									.put(`transactions/${transaction._id}/closeTransaction`, data)
									.then((res) => {
										if (res.data.ok) {
											setErrorMessages({});
											setEditable(false);
											setTriggerUpdate(true);
											toast.success('Policy Info saved!');
										} else {
											toast.error(res.data.error);
										}
									});
							}
						} else {
							api.put('transactions/' + transaction._id, data).then((res) => {
								if (res.data.ok) {
									setErrorMessages({});
									setEditable(false);
									setTriggerUpdate(true);
									toast.success('Policy Info updated!');
								} else {
									toast.error(res.data.error);
								}
							});
						}
					} catch (error) {
						console.log(error);
						toast.error(error);
					} finally {
						setLoading(false);
					}
				}
			} else {
				toast.error('An error has occured!');
				setLoading(false);
			}
		}
	};

	const handleCancel = () => {
		if (!currentData) {
			setUpdatedData(null);
		} else {
			setUpdatedData(cloneDeep(currentData));
		}
		setEditable(false);
	};

	const initPolicyData = () => {
		if (
			transaction.isSelfGenerated &&
			transaction.selectedQuote !== undefined
		) {
			let selectedQuoteData =
				transaction.selectedQuote.responseData.quoteList.find(
					(quote) => quote.selectedByClient
				);
			setUpdatedData({
				policyNo: '',
				principalId: transaction.selectedQuote.principalId,
				principalName: transaction.selectedQuote.principalName,
				premiumAmt: selectedQuoteData.premiumAmt,
				sumInsured: selectedQuoteData.sumInsured,
				startDate: selectedQuoteData.startDate,
				endDate: selectedQuoteData.endDate,
				commission: 0,
				remarks: selectedQuoteData.notes,
			});
		} else {
			setUpdatedData(cloneDeep(initFormData));
		}
		setEditable(true);
	};

	const getActions = (data) => {
		if (editable) {
			return (
				<>
					<Grid item>
						<Button
							onClick={() => handleCancel()}
							variant='outlined'
							color='primary'
							className={classes.button}
						>
							Cancel
						</Button>
					</Grid>
					<Grid item>
						<Button
							onClick={() => handleSave()}
							variant='contained'
							color='primary'
							className={classes.button}
							startIcon={
								<Save
									style={{ fontSize: 24 }}
									colorcode={theme.palette.primary.contrastText}
								/>
							}
						>
							Save
						</Button>
					</Grid>
				</>
			);
		} else {
			return (
				<>
					{transaction.status === 'Completed' && (
						<>
							<Grid item>
								<>
									{!transaction.hasInvoice ? (
										<>
											{userProfile.hasOrganization && (
												<Button
													style={{ marginLeft: 'auto' }}
													variant='contained'
													color='primary'
													component={RouterLink}
													to={'/create-invoice/' + transaction._id}
												>
													Create Invoice
												</Button>
											)}
										</>
									) : (
										<Button
											style={{ marginLeft: 'auto' }}
											variant='contained'
											color='primary'
											component={RouterLink}
											to={'/invoices/' + transaction.invoiceId}
										>
											View Invoice
										</Button>
									)}
								</>
							</Grid>
							<Grid item>
								<Button
									style={{ marginLeft: 'auto' }}
									variant='contained'
									color='primary'
									onClick={() => handleModalOpen('sendRenewalNotice')}
								>
									Send Renewal Notice
								</Button>
							</Grid>
						</>
					)}
					<Grid item>
						<Button
							onClick={() => setEditable(true)}
							variant='outlined'
							color='primary'
							className={classes.button}
							startIcon={
								<Edit
									style={{ fontSize: 24 }}
									colorcode={theme.palette.tertiary.main}
								/>
							}
						>
							Edit Policy
						</Button>
					</Grid>
				</>
			);
		}
	};

	return (
		<>
			{enablePolicyInfo(transaction) ? (
				<>
					{!hasPolicyData() ? (
						<div className={classes.root}>
							<Button
								variant='contained'
								color='primary'
								startIcon={<AddCircleOutlineIcon />}
								onClick={() => initPolicyData()}
							>
								Enter Policy Details
							</Button>
						</div>
					) : (
						<div style={{ padding: '16px 24px', width: '100%' }}>
							{(!transaction.isDirectPurchase || !isFirstEntry) && (
								<Grid
									container
									direction='row'
									alignItems='center'
									justifyContent='flex-end'
									spacing={1}
								>
									{getActions()}
								</Grid>
							)}
							<Grid container spacing={4} style={{ paddingTop: 8 }}>
								<Grid item xs={12} sm={4}>
									<Typography color='textPrimary' variant='body2' align='left'>
										Insurer
									</Typography>
									{transaction.isDirectPurchase && isFirstEntry && editable ? (
										<>
											<FormControl
												style={{ width: '100%' }}
												error={!!errorMessages[`policyData.principalId`]}
											>
												<Select
													native
													name='principalId'
													value={updatedData.principalId}
													inputProps={{
														style: { color: 'unset' },
													}}
													onChange={(e) => handleInputChange(e)}
													variant='filled'
													fullWidth
													autoWidth={true}
													margin='dense'
													style={{ marginTop: 8, marginBottom: 4 }}
												>
													<option key={0} value=''></option>
													{optionData &&
														optionData.map((principal, index) => {
															return (
																<option key={index} value={principal._id}>
																	{principal.shortName}
																</option>
															);
														})}
												</Select>
												{!!errorMessages[`policyData.principalId`] && (
													<FormHelperText>
														{errorMessages[`policyData.principalId`]}
													</FormHelperText>
												)}
											</FormControl>
										</>
									) : (
										<Typography
											color='textPrimary'
											variant='body1'
											align='left'
											style={{ marginTop: 8 }}
										>
											{updatedData.principalName}
										</Typography>
									)}
								</Grid>
								<Box width='100%' />
								<Grid item xs={12} sm={4}>
									<Typography color='textPrimary' variant='body2' align='left'>
										Policy Number
									</Typography>
									{editable ? (
										<TextField
											name='policyNo'
											value={updatedData.policyNo || ''}
											onChange={(e) => handleInputChange(e)}
											InputProps={{
												style: { color: 'unset' },
											}}
											variant={'filled'}
											fullWidth
											margin='dense'
											error={!!errorMessages[`policyData.policyNo`]}
											helperText={
												errorMessages[`policyData.policyNo`] &&
												errorMessages[`policyData.policyNo`]
											}
										/>
									) : (
										<Typography
											color='textPrimary'
											variant='body1'
											align='left'
											style={{ marginTop: 8 }}
										>
											{updatedData.policyNo}
										</Typography>
									)}
								</Grid>
								<Grid item xs={12} sm={editable ? 8 : 4}>
									<Typography color='textPrimary' variant='body2' align='left'>
										Period of Insurance
									</Typography>
									{editable ? (
										<MuiPickersUtilsProvider utils={DateFnsUtils}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<KeyboardDatePicker
													name='startDate'
													disableToolbar
													disabled={editable ? false : true}
													placeholder={editable ? 'DD/MM/YYYY' : ''}
													inputVariant={editable ? 'filled' : 'standard'}
													openTo='year'
													views={['year', 'month', 'date']}
													InputProps={{
														disableUnderline: !editable,
														style: { color: 'unset' },
													}}
													format='dd/MM/yyyy'
													margin='dense'
													fullWidth
													value={
														updatedData.startDate ? updatedData.startDate : null
													}
													autoOk
													variant='inline'
													invalidDateMessage='Invalid Date Format'
													onChange={(date) =>
														handleDateChange('startDate', date)
													}
													error={!!errorMessages[`policyData.startDate`]}
													helperText={
														errorMessages[`policyData.startDate`] &&
														errorMessages[`policyData.startDate`]
													}
												/>
												<span style={{ margin: 8 }}> - </span>
												<KeyboardDatePicker
													name='endDate'
													disableToolbar
													disabled={editable ? false : true}
													placeholder={editable ? 'DD/MM/YYYY' : ''}
													inputVariant={editable ? 'filled' : 'standard'}
													openTo='year'
													views={['year', 'month', 'date']}
													InputProps={{
														disableUnderline: !editable,
														style: { color: 'unset' },
													}}
													format='dd/MM/yyyy'
													margin='dense'
													fullWidth
													value={
														updatedData.endDate ? updatedData.endDate : null
													}
													autoOk
													variant='inline'
													invalidDateMessage='Invalid Date Format'
													onChange={(date) => handleDateChange('endDate', date)}
													error={!!errorMessages[`policyData.endDate`]}
													helperText={
														errorMessages[`policyData.endDate`] &&
														errorMessages[`policyData.endDate`]
													}
												/>
											</div>
										</MuiPickersUtilsProvider>
									) : (
										<Typography
											color='textPrimary'
											variant='body1'
											align='left'
											style={{ marginTop: 8 }}
										>
											{`${moment(updatedData.startDate).format(
												'DD/MM/YYYY'
											)} - ${moment(updatedData.endDate).format('DD/MM/YYYY')}`}
										</Typography>
									)}
								</Grid>
								<Box width='100%' />
								<Grid item xs={12} sm={4}>
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
										}}
									>
										<Typography
											style={{ marginRight: 8 }}
											color='textPrimary'
											variant='body2'
											align='left'
										>
											Total Premium
										</Typography>
										<Tooltip
											content={`Enter Premium inclusive of Goods & Services Tax (GST) if
							applicable. This Premium will be reflected in your Invoice
							to your Client.`}
										/>
									</div>
									{editable ? (
										<TextField
											name='premiumAmt'
											value={updatedData.premiumAmt ?? ''}
											type='number'
											onChange={(e) => handleInputChange(e)}
											placeholder='Enter Total Premium'
											variant='filled'
											fullWidth
											margin='dense'
											InputProps={{
												startAdornment: (
													<InputAdornment
														style={{ marginTop: 0 }}
														position='start'
													>
														<AttachMoneyOutlinedIcon />
													</InputAdornment>
												),
											}}
											error={!!errorMessages[`policyData.premiumAmt`]}
											helperText={
												errorMessages[`policyData.premiumAmt`] &&
												errorMessages[`policyData.premiumAmt`]
											}
										/>
									) : (
										<Typography
											color='textPrimary'
											variant='body1'
											align='left'
											style={{ marginTop: 8 }}
										>
											{updatedData.premiumAmt &&
												currencyFormatter.format(updatedData.premiumAmt)}
										</Typography>
									)}
								</Grid>
								<Grid item xs={12} sm={4}>
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
										}}
									>
										<Typography
											style={{ marginRight: 8 }}
											color='textPrimary'
											variant='body2'
											align='left'
										>
											Sum Insured
										</Typography>
										<Tooltip
											content={`Enter "0" if Sum Insured is available in the
										Quotation/Policy Document`}
										/>
									</div>
									{editable ? (
										<TextField
											name='sumInsured'
											value={updatedData.sumInsured ?? ''}
											type='number'
											onChange={(e) => handleInputChange(e)}
											placeholder='Enter Sum Insured'
											variant='filled'
											fullWidth
											margin='dense'
											InputProps={{
												startAdornment: (
													<InputAdornment
														style={{ marginTop: 0 }}
														position='start'
													>
														<AttachMoneyOutlinedIcon />
													</InputAdornment>
												),
											}}
											error={!!errorMessages[`policyData.sumInsured`]}
											helperText={
												errorMessages[`policyData.sumInsured`] &&
												errorMessages[`policyData.sumInsured`]
											}
										/>
									) : (
										<Typography
											color='textPrimary'
											variant='body1'
											align='left'
											style={{ marginTop: 8 }}
										>
											{updatedData.sumInsured
												? currencyFormatter.format(updatedData.sumInsured)
												: 'Refer to Policy Document'}
										</Typography>
									)}
								</Grid>
								<Grid item xs={12} sm={4}>
									<Typography color='textPrimary' variant='body2' align='left'>
										Agent's Commission
									</Typography>
									{editable ? (
										<TextField
											name='commission'
											value={updatedData.commission ?? ''}
											type='number'
											onChange={(e) => handleInputChange(e)}
											placeholder='Enter Commission'
											variant='filled'
											fullWidth
											margin='dense'
											InputProps={{
												startAdornment: (
													<InputAdornment
														style={{ marginTop: 0 }}
														position='start'
													>
														<AttachMoneyOutlinedIcon />
													</InputAdornment>
												),
											}}
											error={!!errorMessages[`policyData.commission`]}
											helperText={
												errorMessages[`policyData.commission`] &&
												errorMessages[`policyData.commission`]
											}
										/>
									) : (
										<Typography
											color='textPrimary'
											variant='body1'
											align='left'
											style={{ marginTop: 8 }}
										>
											{updatedData.commission &&
												currencyFormatter.format(updatedData.commission)}
										</Typography>
									)}
								</Grid>
								<Grid item xs={12}>
									<Typography color='textPrimary' variant='body2' align='left'>
										Remarks
									</Typography>
									{editable ? (
										<TextField
											name='remarks'
											value={updatedData.remarks || ''}
											onChange={(e) => handleInputChange(e)}
											InputProps={{
												style: { color: 'unset' },
											}}
											variant={'filled'}
											fullWidth
											margin='dense'
											multiline
											minRows={4}
											error={!!errorMessages[`policyData.remarks`]}
											helperText={
												errorMessages[`policyData.remarks`] &&
												errorMessages[`policyData.remarks`]
											}
										/>
									) : (
										<Typography
											color='textPrimary'
											variant='body1'
											align='left'
											style={{ marginTop: 8, whiteSpace: 'pre-wrap' }}
										>
											{updatedData.remarks ? updatedData.remarks : 'N/A'}
										</Typography>
									)}
								</Grid>
								<Grid item xs={12}>
									{isFirstEntry && editable && (
										<>
											<div
												style={{
													display: 'flex',
													alignItems: 'center',
												}}
											>
												<Typography
													style={{ marginRight: 8 }}
													color='textPrimary'
													variant='body2'
													align='left'
												>
													Upload Policy Document
												</Typography>
												<Tooltip content={printReqString()} />
											</div>
											<div
												style={{
													width: '100%',
													marginTop: 8,
													marginBottom: 4,
												}}
											>
												<FileUploader
													fileLimit={1}
													files={updatedData.fileData || null}
													handleFileChange={handleFileChange}
													formErrorMessage={
														errorMessages[`policyDocument`] &&
														errorMessages[`policyDocument`]
													}
												/>
											</div>
										</>
									)}
								</Grid>
								{/* <Grid item xs={12}>
									{!editable && transaction.policyDocuments.length > 0 ? (
										<>
											<Typography
												style={{ marginRight: 8 }}
												color='textPrimary'
												variant='body2'
												align='left'
											>
												Policy Document
											</Typography>
											<div
												style={{
													width: '100%',
													marginTop: 8,
													marginBottom: 4,
												}}
											>
												<FileInfoCard
													file={getPolicyDocument(
														transaction.source,
														transaction.policyDocuments
													)}
												/>
											</div>
										</>
									) : (
										<>
											{editable && (
												<>
													<div
														style={{
															display: 'flex',
															alignItems: 'center',
														}}
													>
														<Typography
															style={{ marginRight: 8 }}
															color='textPrimary'
															variant='body2'
															align='left'
														>
															Upload Policy Document
														</Typography>
														<Tooltip content={printReqString()} />
													</div>
													<div
														style={{
															width: '100%',
															marginTop: 8,
															marginBottom: 4,
														}}
													>
														<FileUploader
															fileLimit={1}
															files={updatedData.fileData || null}
															handleFileChange={handleFileChange}
															formErrorMessage={
																errorMessages[`policyDocument`] &&
																errorMessages[`policyDocument`]
															}
														/>
													</div>
												</>
											)}
										</>
									)}
								</Grid> */}
							</Grid>
							{transaction.isDirectPurchase && isFirstEntry && editable && (
								<>
									<Divider style={{ margin: '16px -24px' }} />
									<div
										id='footer-action-container'
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
									>
										<Button
											onClick={() => handleCancel()}
											variant='outlined'
											color='primary'
											className={classes.button}
										>
											Cancel
										</Button>
										<Button
											onClick={() => handleSave()}
											variant='contained'
											color='primary'
											className={classes.button}
											style={{ marginLeft: 8 }}
										>
											Save
										</Button>
									</div>
								</>
							)}
						</div>
					)}
				</>
			) : (
				<div className={classes.root}>
					<Typography color='textSecondary' variant='subtitle1'>
						This section will display information once Quotations have been sent
						to Client.
					</Typography>
				</div>
			)}
			{modalOpen && (
				<TransactionModal
					open={modalOpen}
					handleClose={handleModalClose}
					body={modalType}
					transactionId={transaction._id}
					data={transaction}
					setTriggerUpdate={setTriggerUpdate}
					size={'md'}
				/>
			)}
		</>
	);
}
