import Backdrop from '../../../components/Backdrop';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import api from '../../../config/api';
import '../styles.css';
import DefaultTemplateList from '../../../components/Data/emailTemplates.json';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3} style={{ padding: 0 }}>
					{children}
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
		'aria-controls': `full-width-tabpanel-${index}`,
	};
}

const useStyles = makeStyles((theme) => ({}));

export default function SendRenewalNotice(props) {
	const { open, handleClose, body, transactionId, data } = props;
	const classes = useStyles();
	const [subject, setSubject] = useState('');
	const [message, setMessage] = useState('');
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		getEmailTemplate();
	}, []);

	const getEmailTemplate = () => {
		if (!loading) {
			setLoading(true);
		}
		// let templateType = 'quoteSelection';
		let templateType = 'renewalNotice';

		api
			.get('email-templates/' + templateType)
			.then((response) => {
				let subjectData;
				let messageData;
				if (response.data.ok) {
					subjectData = replaceTemplateTags(response.data.data.subject, data);
					messageData = replaceTemplateTags(response.data.data.message, data);
				} else {
					let templateData = getDefaultTemplate(templateType);
					subjectData = replaceTemplateTags(templateData.subject, data);
					messageData = replaceTemplateTags(templateData.message, data);
				}
				setSubject(subjectData);
				setMessage(messageData);
				setLoading(false);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const getDefaultTemplate = (templateType) => {
		let templateData = DefaultTemplateList.find((item) => {
			return item.name === templateType;
		});
		return templateData;
	};

	const getNestedValue = (obj, key) => {
		return key.split('.').reduce((acc, part) => acc && acc[part], obj);
	};

	const replaceTemplateTags = (template, data) => {
		return template.replace(/{{(.*?)}}/g, (match, key) => {
			const value = getNestedValue(data, key.trim());

			if (value && typeof value === 'string' && !isNaN(Date.parse(value))) {
				return formatDate(value);
			}

			return value !== undefined ? value : match;
		});
	};

	const formatDate = (isoString) => {
		if (!isoString) return '';
		const date = new Date(isoString);
		return date.toLocaleDateString('en-GB'); // 'en-GB' gives DD/MM/YYYY format
	};

	return (
		<>
			<Backdrop text='Loading Data...' open={loading} />
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<Typography
					style={{ textTransform: 'uppercase' }}
					color='textPrimary'
					variant='h3'
				>
					Send Renewal Notice
				</Typography>
				<IconButton
					style={{ marginLeft: 'auto', padding: 0 }}
					disableRipple
					disableFocusRipple
					disableTouchRipple
					onClick={handleClose}
				>
					<CloseIcon />
				</IconButton>
			</div>
			<div style={{ marginTop: 24 }}>
				<Typography color='textPrimary' variant='body2'>
					Subject
				</Typography>
				<TextField
					style={{ marginTop: 8 }}
					name='subject'
					value={subject || ''}
					onChange={(e) => setSubject(e.target.value)}
					InputProps={{
						style: { color: 'unset' },
					}}
					variant={'filled'}
					fullWidth
					margin='dense'
					multiline
					minRows={1}
				/>
			</div>
			<div style={{ marginTop: 16 }}>
				<Typography color='textPrimary' variant='body2'>
					Message
				</Typography>
				<TextField
					style={{ marginTop: 8 }}
					name='message'
					value={message || ''}
					onChange={(e) => setMessage(e.target.value)}
					InputProps={{
						style: { color: 'unset' },
					}}
					variant={'filled'}
					fullWidth
					margin='dense'
					multiline
					minRows={6}
				/>
			</div>
		</>
	);
}
