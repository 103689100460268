import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Transaction } from '../../../../../components/Icons';

function TabPanel(props) {
	const { children, value, index, ...other } = props;
	const classes = useStyles();
	const isDesktopOrLaptop = useMediaQuery({ minWidth: 768 });

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box
					p={3}
					className={
						isDesktopOrLaptop ? classes.tabArea : classes.tabAreaMobile
					}
				>
					{children}
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
		'aria-controls': `full-width-tabpanel-${index}`,
	};
}

const useStyles = makeStyles((theme) => ({
	tabArea: {
		height: 560,
		padding: 0,
		marginTop: 8,
		display: 'block',
		overflowY: 'auto',
		overflowX: 'hidden',
	},
	tabAreaMobile: {
		padding: 0,
		marginTop: 8,
		display: 'block',
		overflowY: 'auto',
		overflowX: 'hidden',
	},
	centered: {
		padding: '24px 16px',
		textAlign: 'center',
		height: '100%',
		width: '80%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	link: {
		textDecoration: 'none',
		color: '#222222',
	},
}));

const getColor = (status) => {
	switch (status) {
		case 'In-Progress':
			return '#f09106';
		case 'Approved In-Principle':
		case 'Completed':
			return '#7fa572';
		case 'Expired':
		case 'Cancelled':
			return '#f00606';
	}
};

export default function Transactions(props) {
	const {
		loading,
		id,
		ongoingTransactions,
		completedTransactions,
		clientName,
	} = props;
	const classes = useStyles();
	const history = useHistory();
	const theme = useTheme();
	const [value, setValue] = useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<>
			<AppBar
				elevation={1}
				position='static'
				style={{ color: theme.palette.text.disabled, backgroundColor: 'unset' }}
			>
				<Tabs
					value={value}
					onChange={handleChange}
					variant='fullWidth'
					aria-label='full width tabs example'
				>
					<Tab label='Ongoing' {...a11yProps(0)} />
					<Tab label='Completed' {...a11yProps(1)} />
				</Tabs>
			</AppBar>
			<TabPanel value={value} index={0} dir={theme.direction}>
				{loading ? (
					<div className={classes.centered}>
						<CircularProgress size={80} />
					</div>
				) : (
					<>
						{ongoingTransactions.length > 0 ? (
							<>
								<List dense>
									{ongoingTransactions
										.sort((a, b) => {
											return new Date(b.updatedAt) - new Date(a.updatedAt);
										})
										.map((transaction, index) => (
											<ListItem
												key={index}
												className={classes.link}
												style={{ paddingLeft: 24, paddingRight: 24 }}
												button
												component={RouterLink}
												to={`/transactions/${transaction._id}`}
											>
												<ListItemAvatar>
													<Avatar
														style={{ width: 48, height: 48, marginRight: 16 }}
													>
														<Transaction
															style={{ fontSize: 24, marginLeft: 1 }}
															colorcode={theme.palette.primary.contrastText}
														/>
													</Avatar>
												</ListItemAvatar>
												<ListItemText
													disableTypography
													primary={
														<Typography
															style={{ fontWeight: 500 }}
															color='textPrimary'
															variant='body1'
															align='left'
														>
															{`TXN-${transaction.transactionNo} (${transaction.transactionType})`}
														</Typography>
													}
													secondary={
														<Typography
															color='textSecondary'
															variant='body1'
															align='left'
														>
															{`${transaction.insuranceType} ${
																transaction.insuranceSubType
																	? ` - ${transaction.insuranceSubType}`
																	: ''
															}`}
														</Typography>
													}
												/>
												<Typography
													style={{
														width: '47%',
														textAlign: 'center',
														color: getColor(transaction.status),
													}}
												>
													{`${transaction.status} ${
														transaction.subStatus &&
														` (${transaction.subStatus})`
													}`}
												</Typography>
											</ListItem>
										))}
								</List>
								<div
									style={{
										padding: '24px 16px',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									<Button
										component={Link}
										to={`/create-transaction?type=contact&id=${id}`}
										variant='contained'
										color='primary'
									>
										New Transaction
									</Button>
								</div>
							</>
						) : (
							<div
								style={{
									padding: '24px 16px',
									textAlign: 'center',
									height: '100%',
									width: '80%',
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center',
									alignItems: 'center',
									marginLeft: 'auto',
									marginRight: 'auto',
								}}
							>
								<Typography color='primary'>No Transactions found!</Typography>
								<Typography color='primary'>
									{`Click on "New Transaction" to setup a transaction with ${clientName}
									now!`}
								</Typography>

								<Button
									style={{ marginTop: 24 }}
									component={Link}
									to={`/create-transaction?type=contact&id=${id}`}
									variant='contained'
									color='primary'
								>
									New Transaction
								</Button>
							</div>
						)}
					</>
				)}
			</TabPanel>
			<TabPanel value={value} index={1} dir={theme.direction}>
				{loading ? (
					<div className={classes.centered}>
						<CircularProgress size={80} />
					</div>
				) : (
					<>
						{completedTransactions.length > 0 ? (
							<>
								<List dense>
									{completedTransactions
										.sort((a, b) => {
											return new Date(b.updatedAt) - new Date(a.updatedAt);
										})
										.map((transaction, index) => (
											<ListItem
												key={index}
												className={classes.link}
												style={{ paddingLeft: 24, paddingRight: 24 }}
												button
												component={RouterLink}
												to={`/transactions/${transaction._id}`}
											>
												<ListItemAvatar>
													<Avatar
														style={{ width: 48, height: 48, marginRight: 16 }}
													>
														<Transaction
															style={{ fontSize: 24, marginLeft: 1 }}
															colorcode={theme.palette.primary.contrastText}
														/>
													</Avatar>
												</ListItemAvatar>
												<ListItemText
													disableTypography
													primary={
														<Typography
															style={{ fontWeight: 500 }}
															color='textPrimary'
															variant='body1'
															align='left'
														>
															{`TXN-${transaction.transactionNo} (${transaction.transactionType})`}
														</Typography>
													}
													secondary={
														<Typography
															color='textSecondary'
															variant='body1'
															align='left'
														>
															{`${transaction.insuranceType} ${
																transaction.insuranceSubType
																	? ` - ${transaction.insuranceSubType}`
																	: ''
															}`}
														</Typography>
													}
												/>
												<Typography
													style={{
														width: '47%',
														textAlign: 'center',
														color:
															transaction.transactionType === 'Claims' &&
															getColor(transaction.status),
													}}
												>
													{transaction.transactionType === 'Claims' ? (
														<>{`${transaction.status} ${
															transaction.subStatus &&
															` (${transaction.subStatus})`
														}`}</>
													) : (
														<>
															{transaction.policyData.policyNo
																? transaction.policyData.policyNo
																: 'N/A'}
														</>
													)}
												</Typography>
											</ListItem>
										))}
								</List>
								<div
									style={{
										padding: '24px 16px',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									<Button
										component={Link}
										to={`/create-transaction?type=contact&id=${id}`}
										variant='contained'
										color='primary'
									>
										New Transaction
									</Button>
								</div>
							</>
						) : (
							<div className={classes.centered}>
								<Typography color='primary'>No Transactions found!</Typography>
								<Typography color='primary'>
									{`Click on "New Transaction" to setup a transaction with ${clientName}
									now!`}
								</Typography>

								<Button
									style={{ marginTop: 24 }}
									component={Link}
									to={`/create-transaction?type=contact&id=${id}`}
									variant='contained'
									color='primary'
								>
									New Transaction
								</Button>
							</div>
						)}
					</>
				)}
			</TabPanel>
		</>
	);
}
