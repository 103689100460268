import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import DefaultTemplateList from '../../../components/Data/emailTemplates.json';
import api from '../../../config/api';
import { Email } from '../../../utils/validators/schemas';
import EmailPreviewer from '../../../components/EmailPreviewer';
import { Preview, Save } from '../../../components/Icons';

const useStyles = makeStyles((theme) => ({
	cardContainer: {
		cursor: 'pointer',
		borderRadius: 8,
		backgroundColor: theme.palette.background.paper,
		transition: 'all 0.1s',
		'&:hover': {
			boxShadow: `0px 0px 0px 2px ${theme.palette.tertiary.main}`,
			backgroundColor: theme.palette.secondary.main,
		},
		'&:focus': {
			boxShadow: `0px 0px 0px 2px ${theme.palette.tertiary.main}`,
			backgroundColor: theme.palette.secondary.main,
		},
	},
	cardContent: {
		'&:last-child': {
			padding: 16,
		},
	},
	skeleton: {
		marginTop: 8,
		marginBottom: 4,
		borderRadius: 4,
		height: 51,
		width: '100%',
	},
	skeletonLargeText: {
		marginTop: 8,
		marginBottom: 4,
		borderRadius: 4,
		height: 236,

		width: '100%',
	},
	templatesContainer: {
		width: '25%',
		marginRight: 32,
		height: '58vh',
		overflowY: 'auto',
		padding: '8px 16px 8px 8px',
		marginLeft: -8,
	},
}));

export default function EmailTemplates(props) {
	const classes = useStyles();
	const theme = useTheme();
	const [templates, setTemplates] = useState(DefaultTemplateList);
	const [selectedTemplate, setSelectedTemplate] = useState(templates[0].name);
	const [subject, setSubject] = useState('');
	const [message, setMessage] = useState('');
	const [emailData, setEmailData] = useState({});
	const [open, setOpen] = useState(false);
	const [errorMessages, setErrorMessages] = useState({});
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		getData();
	}, [selectedTemplate]);

	const getData = async () => {
		if (!loading) {
			setLoading(true);
		}
		api
			.get('email-templates/' + selectedTemplate)
			.then((response) => {
				if (response.data.ok) {
					setSubject(response.data.data.subject);
					setMessage(response.data.data.message);
					setErrorMessages({});
				} else {
					let templateData = getDefaultTemplate();
					setSubject(templateData.subject);
					setMessage(templateData.message);
				}
				setLoading(false);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const getDefaultTemplate = () => {
		let templateData = templates.find((item) => {
			return item.name === selectedTemplate;
		});
		return templateData;
	};

	const getDisplayName = () => {
		let res = templates.find((item) => {
			return item.name === selectedTemplate;
		});
		return res.displayName;
	};

	const handleFormReset = () => {
		let res = templates.find((item) => {
			return item.name === selectedTemplate;
		});
		setSubject(res.subject);
		setMessage(res.message);
		setErrorMessages({});
	};

	const handleValidation = () => {
		let result = Email.validate(
			{
				subject: subject,
				message: message,
			},
			{ abortEarly: false, convert: false }
		);

		const { error } = result;
		if (!error) {
			setErrorMessages({});
			return true;
		} else {
			const errorData = {};
			for (let item of error.details) {
				const key = item.path.join('.');
				const message = item.message;
				errorData[key] = message;
			}
			setErrorMessages(errorData);
			return false;
		}
	};

	const handleFormSave = (e) => {
		e.preventDefault();
		setLoading(true);

		let valid = handleValidation();

		if (valid) {
			const updatedTemplate = {
				name: selectedTemplate,
				subject: subject,
				message: message,
			};

			api
				.put('email-templates/' + selectedTemplate, updatedTemplate)
				.then((res) => {
					console.log(res.data);
					setErrorMessages({});
					setLoading(false);
					toast.success('Email Template updated!');
				});
		} else {
			toast.error('An error has occured!');
			setLoading(false);
		}
	};

	const togglePreview = () => {
		if (!open) {
			getPreviewData();
		} else {
			setOpen(false);
		}
	};

	const getPreviewData = async () => {
		if (!loading) {
			setLoading(true);
		}
		api
			.post('preview-template/' + selectedTemplate, {
				subject: subject,
				message: message,
			})
			.then((response) => {
				if (response.data.ok) {
					setEmailData(response.data.data);
				} else {
					setEmailData({});
				}
				setLoading(false);
				setOpen(true);
			})
			.catch(function (error) {
				console.log(error);
				setEmailData({});
				setLoading(false);
				setOpen(false);
			});
	};

	return (
		<>
			<Typography variant='body1' style={{ marginBottom: 24 }}>
				Customize language for emails triggered by each specific process stage.
			</Typography>
			<Box display='flex'>
				<Box className={classes.templatesContainer}>
					{templates.map((template, index) => (
						<Card
							key={template.name}
							className={classes.cardContainer}
							style={{
								marginBottom: index < templates.length - 1 && 24,
								marginTop: index >= 1 && 24,
								boxShadow:
									template.name === selectedTemplate &&
									`0px 0px 0px 2px ${theme.palette.tertiary.main}`,
								backgroundColor:
									template.name === selectedTemplate &&
									theme.palette.secondary.main,
							}}
							onClick={() => setSelectedTemplate(template.name)}
						>
							<CardContent classes={{ root: classes.cardContent }}>
								<Typography
									variant='body2'
									component='p'
									style={{
										color:
											template.name === selectedTemplate &&
											theme.palette.tertiary.main,
									}}
								>
									{template.displayName}
								</Typography>
								<Typography
									variant='body1'
									component='p'
									style={{
										color:
											template.name === selectedTemplate &&
											theme.palette.tertiary.main,
									}}
								>
									{template.description}
								</Typography>
							</CardContent>
						</Card>
					))}
				</Box>
				<Box style={{ width: '75%' }}>
					<Card
						style={{
							padding: 24,

							display: 'flex',
							flexDirection: 'column',
							backgroundColor: theme.palette.secondary.light,
							borderRadius: 8,
						}}
					>
						<Typography
							color='textPrimary'
							variant='h5'
							align='left'
							style={{ textTransform: 'uppercase' }}
						>
							{getDisplayName()}
						</Typography>
						<Divider
							style={{
								backgroundColor: theme.palette.primary.light,
								height: 2,
								marginTop: 24,
								marginBottom: 24,
							}}
						/>
						<div style={{ flexGrow: 1 }}>
							<Typography color='textPrimary' variant='body2' align='left'>
								Subject
							</Typography>
							{loading ? (
								<Skeleton
									className={classes.skeleton}
									variant='rect'
									animation='wave'
								/>
							) : (
								<TextField
									name='subject'
									value={subject}
									onChange={(e) => setSubject(e.target.value)}
									placeholder='Enter Subject'
									variant='filled'
									fullWidth
									margin='dense'
									error={!!errorMessages[`subject`]}
									helperText={
										errorMessages[`subject`] && errorMessages[`subject`]
									}
								/>
							)}
							<Typography
								color='textPrimary'
								variant='body2'
								align='left'
								style={{ marginTop: 16 }}
							>
								Message
							</Typography>
							{loading ? (
								<Skeleton
									className={classes.skeletonLargeText}
									variant='rect'
									animation='wave'
								/>
							) : (
								<TextField
									name='message'
									value={message}
									multiline
									onChange={(e) => setMessage(e.target.value)}
									placeholder='Enter Message to Recipient(s)'
									variant='filled'
									fullWidth
									margin='dense'
									minRows={10}
									error={!!errorMessages[`message`]}
									helperText={
										errorMessages[`message`] && errorMessages[`message`]
									}
								/>
							)}
						</div>
						<div style={{ display: 'flex', width: '100%', marginTop: 24 }}>
							<Button
								variant='outlined'
								color='primary'
								startIcon={
									<Preview
										style={{ fontSize: 24 }}
										colorcode={
											selectedTemplate !== 'renewalNotice'
												? theme.palette.tertiary.main
												: '#00000042'
										}
									/>
								}
								onClick={() => togglePreview()}
								disabled={selectedTemplate === 'renewalNotice'}
							>
								Preview
							</Button>
							<Button
								style={{ marginLeft: 'auto', marginRight: 16 }}
								variant='outlined'
								color='primary'
								onClick={() => handleFormReset()}
							>
								Restore to Default
							</Button>
							<Button
								variant='contained'
								color='primary'
								startIcon={
									<Save
										style={{ fontSize: 24 }}
										colorcode={theme.palette.primary.contrastText}
									/>
								}
								onClick={(e) => handleFormSave(e)}
							>
								Save
							</Button>
						</div>
					</Card>
				</Box>
			</Box>
			<EmailPreviewer
				open={open}
				handleClose={() => togglePreview()}
				emailData={emailData}
			/>
		</>
	);
}
