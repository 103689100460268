import Button from '@material-ui/core/Button';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ReactComponent as RequestSent } from '../../assets/Txn_Request_Sent.svg';
import { ReactComponent as RequestProcessing } from '../../assets/Txn_Request_Processing.svg';
import { ReactComponent as Acknowledged } from '../../assets/UW_Acknowledgement.svg';
import { ReactComponent as ClaimConfirmed } from '../../assets/Claim_Confirmed.svg';
import { ReactComponent as RequestError } from '../../assets/Txn_Request_Error.svg';
import { ReactComponent as ClientUploadSuccess } from '../../assets/Client_Upload_Success_v2.svg';
import { ReactComponent as DirectPurchase } from '../../assets/Direct_Purchase_Recorded_v1.svg';

import { ReactComponent as ErrorPage } from '../../assets/Error_PageNotFound_v1.svg';
import ShareLink from '../ShareLink';

const useStyles = makeStyles((theme) => ({
	content: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		placeContent: 'center',
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '100%',
	},
}));

export default function SuccessMessage(props) {
	const classes = useStyles();
	const theme = useTheme();

	const getIcon = (type) => {
		switch (type) {
			case 'direct-purchase':
				return <DirectPurchase />;
			case 'request-sent-for-processing':
			case 'quote-request-sent':
			case 'renewal-request-sent':
			case 'claim-request-sent':
			case 'quote-selection-sent':
			case 'file-request-sent':
			case 'invoice-sent':
				return <RequestSent />;
			case 'request-responded':
				return <Acknowledged />;
			case 'claim-confirmed':
				return <ClaimConfirmed />;
			case 'file-uploaded':
				return <ClientUploadSuccess />;
			case 'quote-selected':
				return <RequestProcessing />;
			case 'invoice-already-created':
			case 'invoice-setting-error':
			case 'request-error':
				return <RequestError />;
			case 'request-not-found':
			case 'invalid-request':
				return <ErrorPage />;
		}
	};

	const getHeader = (type) => {
		switch (type) {
			case 'request-sent-for-processing':
				return 'Quotation successfully sent for Processing!';
			case 'direct-purchase':
				return 'Well done! Transaction details has been successfully recorded.';
			case 'quote-request-sent':
				return 'Request for Quotation successfully sent!';
			case 'renewal-request-sent':
				return 'Request for Renewal successfully sent!';
			case 'claim-request-sent':
				return 'Request for Claim successfully sent!';
			case 'quote-selection-sent':
				return 'Request for Quote Selection successfully sent!';
			case 'file-request-sent':
				return 'Request for Documents successfully sent!';
			case 'invoice-sent':
				return 'Invoice has been successfully sent!';
			case 'request-responded':
				return 'Request acknowledged!';
			case 'claim-confirmed':
				return 'Claim request confirmed!';
			case 'file-uploaded':
				return (
					<>
						Your files were successfully uploaded!
						<br />
						Hang tight while we process your documents
					</>
				);
			case 'quote-selected':
				return 'Quotation submitted for processing!';
			case 'invoice-already-created':
				return 'This Transaction has already been invoiced!';
			case 'invoice-setting-error':
				return 'Invoice Settings has not been configured yet!';
			case 'request-error':
				return (
					<>
						Oops something went wrong...
						<br />
						your request was not sent.
					</>
				);

			case 'request-not-found':
			case 'invalid-request':
				return 'Error 404 / Invalid URL';
		}
	};

	const getContent = (type) => {
		switch (type) {
			case 'request-sent-for-processing':
				return (
					<>
						Once your Insurer has issued the policy documents, you may enter the
						policy information to complete the transaction.
					</>
				);
			case 'direct-purchase':
				return (
					<>
						You're all set! You can now view your completed transaction in
						Transaction Overview page.
					</>
				);
			case 'quote-request-sent':
				return (
					<>
						Your quotation request is now on its way to your Insurer(s)!
						<br />
						In the meanwhile, why not head back to Transaction Overview to work
						on your other transactions?
					</>
				);
			case 'renewal-request-sent':
				return (
					<>
						Your renewal request is now on its way to your Insurer(s)!
						<br />
						In the meanwhile, why not head back to Transaction Overview to work
						on your other transactions?
					</>
				);
			case 'claim-request-sent':
				return (
					<>
						Your claim request is now on its way to your Insurer(s)!
						<br />
						In the meanwhile, why not head back to Transaction Overview to work
						on your other transactions?
					</>
				);
			case 'quote-selection-sent':
				return (
					<>
						Your Quote Selection request is now on its way to your Client!
						<br />
						<ShareLink
							url={`${process.env.REACT_APP_DOMAIN}/quote-selection/${props.requestId}`}
						/>
						<br />
						In the meanwhile, why not head back to Transaction Overview to work
						on your other transactions?
					</>
				);
			case 'file-request-sent':
				return (
					<>
						Your file request is now on its way to your Client!
						<br />
						<ShareLink
							url={`${process.env.REACT_APP_DOMAIN}/file-request/${props.requestId}`}
						/>
						<br />
						In the meanwhile, why not head back to Transaction Overview to work
						on your other transactions?
					</>
				);
			case 'invoice-sent':
				return <>Your invoice is now on its way to your Client!</>;
			case 'request-responded':
				return (
					<>
						The request for quotation has been successfully acknowledged and
						sent to the Agent.
						<br />
						You may now leave this page.
					</>
				);
			case 'claim-confirmed':
				return (
					<>
						Thank you for the claim details submitted. <br />
						Once the claim has been confirmed, you and your Agent will be
						informed via email / sms. <br />
						You may now leave this page.
					</>
				);
			case 'file-uploaded':
				return (
					<>
						This rarely happens but in case you missed out any document(s),
						please reach out to your Agent directly. <br />
						You may now leave this page.
					</>
				);
			case 'quote-selected':
				return (
					<>
						Thank you for confirming your choice. <br />
						The quotation is now with your Agent for processing. <br />
						You may now leave this page.
					</>
				);
			case 'invoice-already-created':
			case 'invoice-setting-error':
				return;
			case 'request-error':
				return (
					<>
						Please resend your request or contact our support to report this
						issue.
						{/* <br /> */}
						{/* Error Info: {props.errorMessages} */}
					</>
				);
			case 'request-not-found':
			case 'invalid-request':
				return (
					<>
						The requested url is not found in this server. <br />
						Please contact us at{' '}
						<a href='mailto:support@uuoni.com'>support@uuoni.com</a> if this
						issue persists.
					</>
				);
		}
	};

	const getButton = (type) => {
		switch (type) {
			case 'request-sent-for-processing':
				return (
					<Button
						style={{
							marginTop: 24,
						}}
						color='primary'
						variant='contained'
						onClick={props.handleClose}
						className={classes.backButton}
					>
						Back to Current View
					</Button>
				);
			case 'direct-purchase':
			case 'quote-request-sent':
			case 'renewal-request-sent':
			case 'claim-request-sent':
			case 'quote-selection-sent':
			case 'file-request-sent':
				return (
					<Button
						className={classes.backButton}
						component={RouterLink}
						to={'/transactions'}
						style={{ marginTop: 24, textDecoration: 'none' }}
						color='primary'
						variant='contained'
					>
						Back to Transaction Overview
					</Button>
				);
			case 'request-error':
				return (
					<div
						style={{
							marginTop: 24,
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<Button
							style={{
								marginRight: 16,
								textDecoration: 'none',
								color: theme.palette.tertiary.main,
							}}
							color='primary'
							variant='outlined'
							className={classes.backButton}
							component={Link}
							target='_blank'
							rel='noopener'
						>
							Contact Us
						</Button>
						<Button
							color='primary'
							variant='contained'
							onClick={props.handleBack}
							className={classes.backButton}
						>
							Try Again
						</Button>
					</div>
				);
			case 'request-responded':
			case 'quote-selected':
			case 'claim-confirmed':
			case 'file-uploaded':
				return;
			case 'invoice-already-created':
			case 'invoice-sent':
				return (
					<Button
						className={classes.backButton}
						component={RouterLink}
						to={'/invoices'}
						style={{ marginTop: 24, textDecoration: 'none' }}
						color='primary'
						variant='contained'
					>
						Back to Invoice Overview
					</Button>
				);
			case 'invoice-setting-error':
				return (
					<Button
						className={classes.backButton}
						component={RouterLink}
						to={'/settings'}
						style={{ marginTop: 24, textDecoration: 'none' }}
						color='primary'
						variant='contained'
					>
						Configure Invoice Settings
					</Button>
				);
			case 'request-not-found':
			case 'invalid-request':
				return;
		}
	};

	return (
		<div className={classes.content}>
			<div style={{ width: 500 }}>{getIcon(props.type)}</div>
			<Typography
				style={{ width: '50%', textAlign: 'center', marginTop: 24 }}
				variant='h2'
				color='textPrimary'
			>
				{getHeader(props.type)}
			</Typography>
			<Typography
				style={{ width: '50%', textAlign: 'center', marginTop: 24 }}
				variant='body1'
				color='textPrimary'
			>
				{getContent(props.type)}
			</Typography>
			{getButton(props.type)}
		</div>
	);
}
