import AppBar from '@material-ui/core/AppBar';
import Backdrop from '../../../components/Backdrop';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Radio from '@material-ui/core/Radio';
import { useTheme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import GetAppIcon from '@material-ui/icons/GetApp';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import PhotoOutlinedIcon from '@material-ui/icons/PhotoOutlined';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined';
import VisibilityIcon from '@material-ui/icons/Visibility';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import SuccessMessage from '../../../components/SuccessMessage';
import api from '../../../config/apiPublic';
import ClientLogin from '../ClientLogin';
import useStyles from '../styles';
import Loader from '../../../components/Loader';
import { SessionTimeout } from '../../../components/Session';
import { ThumbsUp } from '../../../components/Icons';

const currencyFormatter = new Intl.NumberFormat(undefined, {
	style: 'currency',
	currency: 'SGD',
});

function fileType(fileName) {
	return (
		fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) ||
		fileName
	);
}

function QuoteCard(props) {
	const { index, requestId, quote, handleSelectQuote } = props;
	const classes = useStyles();
	const theme = useTheme();

	return (
		<Card
			key={index}
			className={classes.card}
			style={{
				border: quote.checked
					? `1px solid ${theme.palette.tertiary.main}`
					: '1px solid rgba(255, 255, 255, 0)',
				backgroundColor: quote.checked && theme.palette.secondary.main,
				borderRadius: 8,
			}}
		>
			<CardActionArea
				className={classes.cardActionArea}
				component='span'
				onClick={() => handleSelectQuote(requestId, quote.quoteName)}
			>
				<CardContent
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'flex-start',
						padding: 24,
					}}
				>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							width: '100%',
							marginBottom: 16,
						}}
					>
						<Radio
							classes={{ root: classes.radio }}
							style={{ padding: 0 }}
							checked={quote.checked}
							color='primary'
							disableRipple
							name='radio-button'
						/>
						<Typography
							style={{ marginLeft: 16 }}
							color='textPrimary'
							variant='h3'
							align='left'
						>
							{quote.quoteName}
						</Typography>
						<Typography
							style={{
								marginLeft: 'auto',
							}}
							variant='body2'
							color='textPrimary'
						>
							{currencyFormatter.format(quote.premiumAmt)}
						</Typography>
					</div>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							width: '100%',
							marginBottom: 16,
						}}
					>
						<Typography variant='body2' color='textPrimary'>
							Expiry Date:
						</Typography>
						<Typography
							variant='body1'
							color='textPrimary'
							style={{ marginLeft: 8 }}
						>
							{quote.expiryDate !== undefined &&
								moment(quote.expiryDate).format('DD/MM/YYYY')}
						</Typography>
					</div>
					<>
						{quote.responseDocument && (
							<Chip
								icon={
									<div>
										<FileIcon
											labelUppercase
											extension={fileType(quote.responseDocument.displayName)}
											{...defaultStyles[
												fileType(quote.responseDocument.displayName)
											]}
										/>
									</div>
								}
								label={`${quote.responseDocument.documentName} (${quote.responseDocument.displayName})`}
								className={classes.chip}
								style={{ maxWidth: '100%' }}
								onClick={() =>
									window.open(quote.responseDocument.url, '_blank')
								}
								variant='outlined'
								color='primary'
							/>
						)}
					</>
					<Divider
						style={{
							width: '100%',
							backgroundColor: theme.palette.primary.light,
							height: 2,
							marginTop: 16,
							marginBottom: 16,
						}}
					/>
					{/* <Typography
						style={{
							textTransform: 'uppercase',
							marginBottom: 16,
						}}
						variant='h3'
						color='textPrimary'
					>
						Quotation Details
					</Typography>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Typography color='textPrimary' variant='body2'>
								Period of Insurance
							</Typography>
							<Typography
								color='textPrimary'
								variant='body1'
								style={{ marginTop: 8 }}
							>
								{quote.startDate !== undefined &&
									quote.endDate !== undefined &&
									`${moment(quote.startDate).format('DD/MM/YYYY')} - ${moment(
										quote.endDate
									).format('DD/MM/YYYY')} `}
							</Typography>
						</Grid>
						<Grid item xs={6}>
							<Typography color='textPrimary' variant='body2'>
								Total Premium
							</Typography>
							<Typography
								color='textPrimary'
								variant='body1'
								style={{ marginTop: 8 }}
							>
								{quote.premiumAmt !== undefined &&
									currencyFormatter.format(quote.premiumAmt)}
							</Typography>
						</Grid>
						<Grid item xs={6}>
							<Typography color='textPrimary' variant='body2'>
								Sum Insured
							</Typography>
							<Typography
								color='textPrimary'
								variant='body1'
								style={{ marginTop: 8 }}
							>
								{quote.sumInsured
									? currencyFormatter.format(quote.sumInsured)
									: 'Refer to Quote Document'}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography color='textPrimary' variant='body2'>
								Notes
							</Typography>
							<Typography
								color='textPrimary'
								variant='body1'
								style={{ marginTop: 8 }}
							>
								{quote.notes !== undefined && quote.notes}
							</Typography>
						</Grid>
					</Grid>
					<Divider
						style={{
							width: '100%',
							backgroundColor: theme.palette.primary.light,
							height: 2,
							marginTop: 16,
							marginBottom: 16,
						}}
					/> */}
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							width: '100%',
							marginBottom: 16,
						}}
					>
						<Typography
							style={{
								textTransform: 'uppercase',
							}}
							variant='h3'
							color='textPrimary'
						>
							Agent's Remarks
						</Typography>
						{quote.recommended && (
							<Button
								style={{
									marginLeft: 'auto',
									backgroundColor: theme.palette.success.main,
									color: theme.palette.secondary.light,
									cursor: 'pointer',
								}}
								disableElevation
								disableFocusRipple
								disableTouchRipple
								disableRipple
								variant='contained'
								startIcon={
									<ThumbsUp
										style={{ fontSize: 24 }}
										colorcode={theme.palette.primary.contrastText}
									/>
								}
							>
								Recommended
							</Button>
						)}
					</div>
					<Typography
						style={{ overflowWrap: 'anywhere', whiteSpace: 'pre-wrap' }}
						variant='body1'
						color='textPrimary'
					>
						{quote.recommendationRemarks
							? quote.recommendationRemarks
							: 'Your agent has not entered any specific recommendations!'}
					</Typography>
				</CardContent>
			</CardActionArea>
		</Card>
	);
}

function Column(props) {
	const { quoteRequest, handleSelectQuote } = props;
	const classes = useStyles();
	const theme = useTheme();

	return (
		<div className={classes.columnWrapper}>
			<Typography variant='body2' style={{ textTransform: 'uppercase' }}>
				{quoteRequest.principalName}
			</Typography>
			<Divider
				style={{ margin: '16px 0px', background: theme.palette.secondary.main }}
			/>
			<div id='card-container'>
				{quoteRequest.quoteList.map((quote, index) => (
					<QuoteCard
						key={quote.quoteName}
						index={index}
						requestId={quoteRequest.requestId}
						quote={quote}
						handleSelectQuote={handleSelectQuote}
					/>
				))}
			</div>
		</div>
	);
}

export default function QuoteSelection(props) {
	const { id } = useParams();
	const classes = useStyles();
	const theme = useTheme();
	const [quoteSelectionRequest, setQuoteSelectionRequest] = useState(null);
	const [selectedQuote, setSelectedQuote] = useState(null);
	const [isFormInvalid, setIsFormInvalid] = useState(false);
	const [success, setSuccess] = useState(false);
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [hasAuth, setHasAuth] = useState(false);

	const getData = () => {
		if (!loading) {
			setLoading(true);
		}
		api
			.get(`external/client-requests/quoteSelection/${id}`)
			.then((response) => {
				if (response.data.ok) {
					let list = response.data.data.quoteList.map((quoteRequest) => {
						return {
							...quoteRequest,
							quoteList: quoteRequest.quoteList.map((quote) => ({
								...quote,
								checked: false,
							})),
						};
					});
					let initializedRequest = {
						...response.data.data,
						quoteList: list,
					};
					setQuoteSelectionRequest(initializedRequest);
				} else {
					setQuoteSelectionRequest(null);
				}
				setLoading(false);
			})
			.catch(function (error) {
				setLoading(false);
				setQuoteSelectionRequest(null);
				console.log(error);
			});
	};

	// useEffect(() => {
	// 	if (hasAuth) getData();
	// }, [hasAuth]);
	useEffect(() => {
		getData();
	}, []);

	const fileSize = (size) => {
		if (size === 0) {
			return '0 Bytes';
		}
		const k = 1024;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
		const i = Math.floor(Math.log(size) / Math.log(k));
		return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
	};

	const handleSelectQuote = (requestId, quoteName) => {
		let updatedQuotes = [...quoteSelectionRequest.quoteList].map(
			(quoteRequest) =>
				quoteRequest.requestId === requestId
					? {
							...quoteRequest,
							quoteList: quoteRequest.quoteList.map((quote) =>
								quote.quoteName === quoteName
									? {
											...quote,
											checked: true,
									  }
									: {
											...quote,
											checked: false,
									  }
							),
					  }
					: {
							...quoteRequest,
							quoteList: quoteRequest.quoteList.map((quote) => {
								return { ...quote, checked: false };
							}),
					  }
		);

		setQuoteSelectionRequest((prev) => ({
			...prev,
			quoteList: updatedQuotes,
		}));
		setSelectedQuote(`${requestId}-${quoteName}`);
	};

	const getHeader = () => {
		return (
			<>
				Selection of Quotation - {quoteSelectionRequest.insuranceType}
				{quoteSelectionRequest.insuranceSubType && (
					<> ({quoteSelectionRequest.insuranceSubType})</>
				)}{' '}
				{quoteSelectionRequest.transactionType} -{' '}
				{quoteSelectionRequest.client.contactName}
			</>
		);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (loading) return;
		setOpen(true);
		let data = {
			selectedQuote: selectedQuote,
		};

		try {
			api
				.put(`external/client-requests/quoteSelection/${id}`, data)
				.then((res) => {
					if (res.data.ok) {
						setSuccess(true);
						setOpen(false);
					} else {
						setSuccess(false);
						toast.error(res.data.error);
						setOpen(false);
					}
				});
		} catch (error) {
			console.log(error);
			setSuccess(false);
			toast.error(error);
			setOpen(false);
		}
	};

	// if (!hasAuth)
	// 	return (
	// 		<ClientLogin
	// 			reqType='quoteSelection'
	// 			reqId={id}
	// 			hasAuth={hasAuth}
	// 			setHasAuth={setHasAuth}
	// 		/>
	// 	);

	// if (hasAuth && loading && !quoteSelectionRequest) return <Loader size='lg' />;
	// if (hasAuth && !loading && !quoteSelectionRequest)
	if (loading && !quoteSelectionRequest) return <Loader size='lg' />;
	if (!loading && !quoteSelectionRequest)
		return (
			<>
				<AppBar position='fixed' className={classes.appBar}>
					<Toolbar className={classes.toolBar} disableGutters>
						<div className='brandContainer'>
							<Button
								target='_blank'
								href='https://www.uuoni.com'
								style={{ padding: 0, backgroundColor: 'transparent' }}
								disableRipple
								disableFocusRipple
								disableElevation
							>
								<img
									className='brand-logo'
									src={`${process.env.REACT_APP_CDN_URL}/logo-brand-white.png`}
									alt=''
								/>
							</Button>
						</div>
					</Toolbar>
				</AppBar>
				<SuccessMessage type={'request-not-found'} />
			</>
		);
	return (
		<>
			<AppBar position='fixed' className={classes.appBar}>
				<Toolbar className={classes.toolBar} disableGutters>
					<div className='brandContainer'>
						<Button
							target='_blank'
							href='https://www.uuoni.com'
							style={{ padding: 0, backgroundColor: 'transparent' }}
							disableRipple
							disableFocusRipple
							disableElevation
						>
							<img
								className='brand-logo'
								src={`${process.env.REACT_APP_CDN_URL}/logo-brand-white.png`}
								alt=''
							/>
						</Button>
					</div>
				</Toolbar>
			</AppBar>
			{quoteSelectionRequest &&
			quoteSelectionRequest.status !== 'Pending Response' ? (
				<SuccessMessage type={'invalid-request'} />
			) : (
				<>
					{success ? (
						<SuccessMessage type={'quote-selected'} />
					) : (
						<div className={classes.content}>
							<Backdrop text='Processing Information...' open={open} />
							<div className='header' style={{ marginBottom: 32 }}>
								<Typography variant='h1' color='textPrimary'>
									{getHeader()}
								</Typography>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										marginTop: 16,
									}}
								>
									<Typography
										style={{ textTransform: 'uppercase' }}
										variant='h5'
										color='textPrimary'
									>
										Requested by{' '}
										{`${quoteSelectionRequest.agentInfo.firstName} ${quoteSelectionRequest.agentInfo.lastName}`}
									</Typography>
									<div style={{ marginLeft: 16 }}>
										<IconButton
											component={Link}
											target='_blank'
											href={`tel:${quoteSelectionRequest.agentInfo.mobileNo}`}
											size='small'
										>
											<WhatsAppIcon />
										</IconButton>
										<IconButton
											component={Link}
											href={`mailto:${quoteSelectionRequest.agentInfo.email}`}
											size='small'
										>
											<MailOutlineIcon />
										</IconButton>
									</div>
								</div>
							</div>
							<div className='body' style={{ marginBottom: 32 }}>
								<Typography
									style={{ textTransform: 'uppercase' }}
									variant='h3'
									color='textPrimary'
								>
									List of Quotation(s)
								</Typography>
								<Divider
									style={{
										width: '100%',
										backgroundColor: theme.palette.primary.light,
										height: 2,
										marginTop: 16,
										marginBottom: 16,
									}}
								/>
								<Typography
									style={{ marginBottom: 24 }}
									variant='body1'
									color='textPrimary'
								>
									Please select <b>ONE</b> Quotation below to proceed
								</Typography>
								<Grid container spacing={4}>
									{quoteSelectionRequest.quoteList.map(
										(quoteRequest, index) => (
											<Grid key={index} item xs={12} sm={6} md={4} lg={3}>
												<Column
													quoteRequest={quoteRequest}
													handleSelectQuote={handleSelectQuote}
												/>
											</Grid>
										)
									)}
								</Grid>
							</div>
							<div
								className='form-footer'
								style={{ display: 'flex', alignItems: 'center' }}
							>
								<Button
									style={{
										marginLeft: 'auto',
									}}
									variant='contained'
									color='primary'
									disabled={!selectedQuote || loading || open}
									onClick={handleSubmit}
								>
									Submit
								</Button>
							</div>
						</div>
					)}
				</>
			)}
			{/* {hasAuth && <SessionTimeout onTimeout={() => setHasAuth(false)} />} */}
		</>
	);
}
